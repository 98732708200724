import gql from "graphql-tag";

export const product = gql `
  query getProductById($id: ID!) {
    product(id: $id) {
      id
      title
      featuredImage {
        url
      }
      description
      descriptionHtml
      ali1688: metafield(namespace: "1688", key: "fields") {
        id
        createdAt
        value
      }
      images(first: 10) {
        edges {
          node {
            id
            url
          }
        }
      }
      options(first: 10) {
        id
        name
        values
      }
      variants(first: 1000) {
        edges {
          node {
            id
            title
            quantityAvailable
            sku
            price
            image {
              url
            }
          }
        }
      }
    }
  }
`

export const cartCreate = gql `
mutation cartCreate($input: CartInput) {
  cartCreate(input: $input) {
    cart {
      id
    }
    userErrors {
      field
      message
    }
  }
}
`

export const cartLinesAdd = gql `
mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
  cartLinesAdd(cartId: $cartId, lines: $lines) {
    cart {
      id
    }
    userErrors {
      field
      message
    }
  }
}
`