import {
    query,
    mutate
} from "@/utils/apollo-utils.js"
import axios from '@/utils/request';

import {
    product as productGql,
    cartCreate as cartCreateGql,
    cartLinesAdd as cartLinesAddGql
} from "./gqls.js"

export async function cartCreate(payload) {
    return mutate(cartCreateGql, payload)
}

export async function cartLinesAdd(payload) {
    return mutate(cartLinesAddGql, payload)
}

export function getJsonData() {
    fetch("./data.json")
        .then((response) => response.json())
        .then((json) => console.log(json))
}

export async function product(payload) {
    return axios.post('shopify/query/product', payload, {
        'Content-Type': 'application/x-www-form-urlencoded'
    });
}

export async function query1688SyncResult(ali1688ItemId) {
    return axios.get(`/product/query1688SyncResult/${ali1688ItemId}`);
}