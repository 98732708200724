<template>
  <div>
    <div class="shopee-item mobile-fixed-theme" v-if="product && product.compare_platform && product.compare_product_title
      " @click="toShopee">
      <van-image width="100" height="100" fit="scale-down" :src="product.compare_product_image && product.compare_product_image.value
        " />
      <div class="shopee-item-content">
        <div class="product-title">
          {{ product.compare_product_title.value }}
        </div>
        <div style="padding: 5px 0px">
          <span class="shopee-price" v-if="compareProductValid(
            product.compare_at_platform_price &&
            product.compare_at_platform_price.value
          )
            ">฿{{
    product.compare_at_platform_price &&
    product.compare_at_platform_price.value
  }}</span>
          <span class="shopee-sold" v-if="product.compare_product_sold && product.compare_product_sold.value
              ">{{
      product.compare_product_sold && product.compare_product_sold.value
    }}
            {{ $t("sold") }}</span>
        </div>
        <div v-if="product.compare_product_rating_star">
          <van-rate v-model="rate" allow-half color="#F9BA4A" size="12" void-icon="star" void-color="#eee"
            readonly /><span style="font-size: 0.8rem">{{ $t("ratings", { ratings: getShopeeRating() }) }}
          </span>
        </div>
      </div>
    </div>

    <van-swipe :autoplay="3000" lazy-render style="height: 500px" :class="product && product.compare_platform && product.compare_product_title
      ? 'margin-top-140'
      : ''
      ">
      <van-swipe-item v-for="image in images" :key="image.node.id">
        <van-image width="100%" height="100%" :src="image.node.url" fit="cover" />
      </van-swipe-item>
    </van-swipe>

    <div :class="'navi-bar mobile-fixed-theme ' +
      (product && product.compare_platform && product.compare_product_title
        ? 'margin-top-145'
        : '')
      ">
      <div class="navi-back" @click="backForward" v-if="back">
        <van-icon name="arrow-left" size="1.5rem" style="line-height: 40px" />
      </div>

      <div class="navi-right">
        <div class="navi-home" @click="toPageRoute('home')">
          <van-icon name="wap-home-o" size="1.5rem" style="line-height: 40px" />
        </div>
        <div class="navi-home" @click="toPageRoute('cart')" style="margin-left: 10px">
          <van-icon name="shopping-cart-o" size="1.5rem" style="line-height: 40px" :badge="badge" />
        </div>
      </div>
    </div>

    <div class="content" v-if="product">
      <span class="title" style="display: block; background-color: white">{{
        product.local_title && product.local_title.value
        ? product.local_title.value
        : product.title
      }}</span>
      <div style="display: flex; background-color: white; padding: 5px 15px">
        <div class="product-price" style="flex: 1" v-if="minPrice">
          <span>฿{{ minPrice.thb }}</span>
          <div style="color: #333" v-if="minPrice.rmb">¥{{ minPrice.rmb }}</div>
        </div>
        <span style="font-size: 0.9rem; color: #555" v-if="getMinStartAmount">
          {{ $t("Minimum order quantity") }}: {{ getMinStartAmount() }}
        </span>
      </div>
      <div class="range-price" v-if="product &&
        product.sku_price_type &&
        product.sku_price_type.value == 'rangePrice'
        ">
        <van-grid :column-num="4">
          <van-grid-item>
            {{ $t("价格") }}
          </van-grid-item>
          <van-grid-item v-for="price in priceRange" :key="price.startAmount" class="range-price-price">
            ฿{{ price.localPrice }}<br />
            ¥{{ price.price }}
          </van-grid-item>
        </van-grid>

        <van-grid :column-num="4">
          <van-grid-item>
            {{ $t("起批量") }}
          </van-grid-item>
          <van-grid-item v-for="price in priceRange" :key="price.startAmount" class="range-price-start-amount">
            {{ price.description }}
          </van-grid-item>
        </van-grid>
      </div>

      <div style="padding: 15px; background-color: white; margin-top: 10px" v-if="product && product.vendor == 'ali1688'">
        {{ $t("Shipping") }}
        <div style="padding-left: 30px; margin-top: 10px; font-size: 0.9rem">
          <div class="shipping-item">
            <div class="shipping-title">
              {{ $t("The supplier ships the goods to our warehouse in China") }}
            </div>
            <div class="shipping-icon-container">
              <div class="shipping-icon">
                <van-image width="20" height="20" style="margin-top: 3px" :src="icons.shop" />
              </div>
            </div>
            <div class="shipping-step-line"></div>
          </div>
          <div class="shipping-item">
            <div class="shipping-title">
              {{
                $t(
                  "You pay the cross border freight before a cross border logistic company ships the goods to Thailand."
                )
              }}
              <span style="color: #ee502f" @click="toCalculator">{{
                $t("Clck to Estimate freight and Transit Time")
              }}</span>
            </div>
            <div class="shipping-icon-container">
              <div class="shipping-icon">
                <van-image width="20" height="20" style="margin-top: 3px" :src="icons.warehouse" />
              </div>
            </div>
            <div class="shipping-step-line"></div>
          </div>
          <div class="shipping-item">
            <div class="shipping-title">
              {{
                $t("A local express company delivers the goods to your address")
              }}
            </div>
            <div class="shipping-icon-container">
              <div class="shipping-icon">
                <van-image width="20" height="20" style="margin-top: 3px" :src="icons.delivery_man" />
              </div>
            </div>
          </div>
        </div>
        <div style="text-align: right; color: #ee502f; font-size: 0.8rem" @click="toShippingPolicy">
          {{ $t("See Shipping Details >>") }}'
        </div>
      </div>

      <div style="
          display: block;
          background-color: white;
          padding: 15px;
          margin-top: 10px;
        " v-html="product && product.descriptionHtml"></div>
    </div>

    <div class="add-to-bag van-hairline--top mobile-fixed-theme">
      <div class="cart-buy-item" @click="customerService">
        <van-icon name="balance-pay" />
        <span style="display: inline-block; margin-left: 5px; line-height: 55px">{{ $t("客服") }}</span>
      </div>
      <span class="cart-divider"></span>
      <div class="cart-buy-item" @click="showSkuByAction('addtocart')">
        <van-icon name="shopping-cart-o" />
        <span style="display: inline-block; margin-left: 5px; line-height: 55px">{{ $t("ADD TO CART") }}</span>
      </div>
    </div>

    <van-action-sheet v-model="optionSelectShow" :closeable="false" style="height: 680px">
      <div class="select-product-detail van-hairline--bottom" v-if="product">
        <van-image width="80" height="80" v-if="checkedVariant && checkedVariant.image
          ? checkedVariant.image
          : product.featuredImage && product.featuredImage.url
          " @click="imagePreview" :src="checkedVariant && checkedVariant.image
    ? checkedVariant.image
    : product.featuredImage && product.featuredImage.url
    " />
        <div style="flex: 1; padding: 0px 10px">
          <div class="variant-price">
            ฿{{
              checkedVariant
              ? checkedVariant.priceTH
                ? checkedVariant.priceTH
                : checkedVariant.price
              : minPrice.thb
            }}<br />
            <span style="color: #333; font-size: 1rem">¥{{
              checkedVariant
              ? checkedVariant.priceRMB
                ? checkedVariant.priceRMB
                : checkedVariant.cny_price
              : minPrice.rmb
            }}</span>
          </div>
          <div v-if="product.sku_price_type &&
              product.sku_price_type.value == 'rangePrice'
              ">
            {{ getMinStartAmountDesc() }}
          </div>
          <div class="product-rules">
            {{
              checkedVariant
              ? formatThValue(checkedVariant.title)
              : variantIfCheckedvariantNull()
            }}
          </div>
        </div>
      </div>
      <div class="option-select-content">
        <div class="options-middle">
          <div v-for="option in options" :key="option.id" class="van-hairline--bottom" style="padding-bottom: 10px">
            <span class="option-name">{{ option.name }}</span>
            <div>
              <span v-for="variant in option.values" :key="variant.value"
                :class="variant.checked ? 'option-select' : 'option-unselect'" @click="checkOption(option, variant)">
                {{ formatThValue(variant.value) }}</span>
            </div>
          </div>
          <span class="option-name">{{ $t("Quantity") }}</span>
          <div style="display: flex; align-items: center">
            <van-stepper v-model="quantity" :min="getMinStartAmount()" :max="checkedVariant ? 100000 : 0" theme="round"
              style="display: inline-block" @change="quantityChange" input-width="40px" button-size="25px" />
            <span class="stockout" v-if="!checkedVariant">{{
              $t("缺货")
            }}</span>
          </div>
        </div>
      </div>

      <div :class="'cart-confirm ' + (loading ? 'action-loading' : '')" @click="actionConfirm">
        <van-loading color="white" size="20px" v-if="loading" />
        {{ "buynow" == actionType ? $t("Confirm") : $t("ADD TO CART") }}
      </div>
    </van-action-sheet>

    <div class="van-toast-cancel" v-if="cancelLoadingShow">
      <span class="van-loading__spinner van-loading__spinner--circular">
        <svg viewBox="25 25 50 50" class="van-loading__circular">
          <circle cx="50" cy="50" r="20" fill="none"></circle>
        </svg>
      </span>
      <span class="cancel-loading">{{ cancelLoadingText }}</span>
      <span class="cancel-loading-cancel" @click="cancelWait()">{{
        $t("取消等待")
      }}</span>
    </div>

    <van-image-preview v-model="imagePreviewShow" closeable
      :images="product && product.compare_product_image ? [product.compare_product_image.value] : []">
    </van-image-preview>
  </div>
</template>

<script>
import delivery_man from "@/assets/images/delivery_man.png";
import shop from "@/assets/images/shop.png";
import warehouse from "@/assets/images/warehouse.png";
import {
  Swipe,
  SwipeItem,
  Image,
  Button,
  Icon,
  ActionSheet,
  Toast,
  Stepper,
  ImagePreview,
  Loading,
  Grid,
  GridItem,
  Rate,
} from "vant";
import { product, query1688SyncResult } from "./service";
import qs from "qs";
import { addCart, cartCount } from "@/utils/cart.js";
import { trackViewContent, trackAddToCart } from "@/utils/statistic";
import { LINE } from "@/utils/constants";
import { openWebPageBlank } from "@/utils/pages";
import { isLogin } from "@/utils/token";
import {
  toAuthorizationLogin,
  openWebPage,
  openWebPageLocale,
} from "@/utils/pages";
import { formatThValue } from "@/utils/utils";
import { getChatWoot } from "@/utils/storage";

export default {
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Image.name]: Image,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [ActionSheet.name]: ActionSheet,
    [Stepper.name]: Stepper,
    [Loading.name]: Loading,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Rate.name]: Rate,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      icons: { warehouse, shop, delivery_man },
      images: [],
      options: [],
      quantity: 1,
      checkedVariant: null,
      optionSelectShow: false,
      actionType: null,
      loading: false,
      priceRange: [],
      back: this.$route.query.back ? this.$route.query.back : true,
      badge: "",
      variants: [],
      minPrice: null,
      product: null,
      rate: 0,
      auth: this.$route.query.auth,
      retryTime: 0,
      maxRetryTime: 33,
      querySyncFinish: false,
      cancelLoadingShow: false,
      itemId: "",
      cancelLoadingText: this.$t("加载中..."),
      imagePreviewShow: false
    };
  },
  methods: {
    //缺货时显示规则
    variantIfCheckedvariantNull() {
      let checkOpts = this.options.map((it) => {
        let rule = it.values.find((value) => {
          return value.checked;
        });
        return rule && { name: it.name, value: rule.value };
      });

      const values = checkOpts.map((co) => {
        return co.value;
      });
      return values.join(" / ");
    },
    // line咨询客服
    customerService() {
      const url = "https://line.me/R/oaMessage/%40beericher/?" + encodeURIComponent(`${this.product.title} ${LINE.LOGIN_CALLBACK}${this.$route.fullPath}`)
      openWebPageBlank(url)
    },
    compareProductValid(price) {
      return price && price > 0;
    },
    quantityChange(value) {
      if (!value) return;
      this.getAppliedDiscount(this.checkedVariant);
    },
    toShippingPolicy() {
      openWebPageLocale("helpcenter", "shipping-policy");
    },
    toCalculator() {
      this.$router.push({
        name: "calculator",
      });
    },
    formatThValue(value) {
      return formatThValue(value);
    },
    backForward() {
      if (this.auth) {
        this.$router.push({
          name: "Home",
        });
      } else this.$router.go(-1);
    },
    getShopeeRateNumber(rate) {
      if (!rate) return 0;
      return Math.round(rate * 10) / 10;
    },
    getShopeeRating() {
      if (this.product.compare_product_rating_star)
        return parseFloat(
          this.product.compare_product_rating_star.value
        ).toFixed(1);
    },
    toShopee() {
      if (this.product.compare_product_url && this.product.compare_product_url.value)
        openWebPage(this.product.compare_product_url.value);
      else if (this.product.compare_product_image && this.product.compare_product_image.value)
        this.imagePreviewShow = true

    },
    toPageRoute(name) {
      this.$store.commit("setHomeIndex", "home" == name ? 0 : 2);
      this.$router.push({ name: "Home" });
    },
    getCartCount() {
      const count = cartCount();
      this.badge = count <= 0 ? "" : `${count}`;
    },
    getMinStartAmountDesc() {
      if (this.priceRange && this.priceRange.length > 0) {
        const { description } = this.priceRange[0];
        return description;
      }
    },
    getMinStartAmount() {
      if (!this.checkedVariant) return 0;

      if (this.priceRange && this.priceRange.length > 0) {
        const { startAmount } = this.priceRange[0];
        return startAmount;
      }
      return 1;
    },
    async queryProduct() {
      let payload = {
        id: this.id,
        returnsQlStr: `{
                  id
                  title
                  vendor
                  status
                  priceRange {
                    minVariantPrice {
                        amount
                    }
                  }
                  featuredImage {
                      url
                  }
                  descriptionHtml
                  images(first: 10) {
                    edges {
                      node {
                        id
                        url
                      }
                    }
                  }
                  options(first: 10) {
                    id
                    name
                    values
                  }
                  variants(first: 100) {
                    edges {
                      node {
                        id
                        title
                        sku
                        price
                        image {
                          url
                        }
                        selectedOptions {
                          name
                          value
                        }
                        cny_price:metafield(namespace:\\"custom\\",key:\\"cny_price\\"){
                          value
                        }
                      }
                    }
                  }
                  local_title:metafield(namespace:\\"custom\\",key:\\"local_title\\"){
                    value
                  }
                  price_ranges:metafield(namespace:\\"ali1688\\",key:\\"price_ranges\\"){
                    value
                  }
                  compare_product_rating_star:metafield(namespace:\\"products\\",key:\\"compare_product_rating_star\\"){
                    value
                  }
                  seller_member_id:metafield(namespace:\\"ali1688\\",key:\\"seller_member_id\\"){
                    value
                  }
                  compare_product_image:metafield(namespace:\\"products\\",key:\\"compare_product_image\\"){
                    value
                  }
                  compare_product_title:metafield(namespace:\\"products\\",key:\\"compare_product_title\\"){
                    value
                  }
                  compare_product_sold:metafield(namespace:\\"products\\",key:\\"compare_product_sold\\"){
                    value
                  }
                  compare_product_url:metafield(namespace:\\"products\\",key:\\"compare_product_url\\"){
                    value
                  }
                  compare_platform:metafield(namespace:\\"products\\",key:\\"compare_platform\\"){
                    value
                  }
                  compare_at_platform_price:metafield(namespace:\\"products\\",key:\\"compare_at_platform_price\\"){
                    value
                  }
                  sku_price_type:metafield(namespace:\\"ali1688\\",key:\\"sku_price_type\\"){
                    value
                  }
                  metafield_variants:metafield(namespace:\\"custom\\",key:\\"variants\\"){
                    value
                  }
                }`,
      };

      const chatwoot = getChatWoot();
      if (chatwoot) {
        payload = { ...chatwoot, ...payload };
      }

      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const res = await product(qs.stringify(payload));
      const { extra } = res;

      // let extra = {
      //   researchTaskId: "123",
      //   itemId: 13681437466,
      // };
      if (extra && extra.researchTaskId) {
        const { itemId } = extra;
        if (itemId) this.itemId = itemId;
        this.querySyncAli88Result();
      }

      const { product: data, error } = res.data;
      if (data) {
        this.product = data;

        const { status } = data;
        if (status && status == "ARCHIVED") {
          this.toTakeOff();
        }

        const { images, options, variants, featuredImage, metafield_variants } =
          data;
        this.images = images && images.edges;
        this.featuredImage = featuredImage;
        let opts = options.map((it) => {
          const { id, name } = it;
          let rules = it.values.map((v, index) => {
            return { value: formatThValue(v), checked: index === 0 };
          });
          return { id, name: formatThValue(name), values: rules };
        });
        this.options = opts;

        let prices = [];
        //转换variant metafileds
        variants.edges.forEach(({ node }) => {
          const { selectedOptions, cny_price } = node;
          const optionsMatch = {};
          selectedOptions.forEach((opt) => {
            const { name, value } = opt;
            optionsMatch[formatThValue(name)] = formatThValue(value);
          });
          node.optionsMatch = optionsMatch;

          const opts = selectedOptions.map((opt) => {
            const { value } = opt;
            return formatThValue(value);
          });
          const osOri = selectedOptions.map((opt) => {
            const { value } = opt;
            return value;
          });
          node.title = opts.join(" / ");
          node.titleOri = osOri.join(" / ");

          prices.push({ rmb: cny_price && cny_price.value, thb: node.price });
        });

        this.variants = variants;
        prices = prices.sort((a, b) => {
          return a.thb - b.thb;
        });

        if (prices.length != 0) {
          this.minPrice = prices[0];
        }

        const { price_ranges } = this.product;
        if (price_ranges) {
          const { rmb, local } = JSON.parse(price_ranges.value);
          let priceRange = rmb.sort((a, b) => {
            return a.startAmount - b.startAmount;
          });

          let priceRangeLocal =
            local &&
            local.sort((a, b) => {
              return a.startAmount - b.startAmount;
            });

          priceRange.forEach((it, index) => {
            const { startAmount } = it;
            if (index === 0) {
              it.description = this.$t("amount件起批", {
                amount: startAmount,
              });
            } else if (index === priceRange.length - 1) {
              it.description = `≥${startAmount}${this.$t("件_")}`;
            } else {
              const { startAmount: maxAmount } = priceRange[index + 1];
              it.description = `${startAmount}-${maxAmount}${this.$t("件_")}`;
            }

            if (priceRangeLocal && priceRangeLocal.length != 0) {
              const { price } = priceRangeLocal[index];
              it.localPrice = price;
            }
          });
          this.priceRange = priceRange;
        }

        this.rate = this.getShopeeRateNumber(
          this.product.compare_product_rating_star &&
          this.product.compare_product_rating_star.value
        );

        if (metafield_variants) {
          const rvs = JSON.parse(metafield_variants.value);
          const redundantVariants = rvs.map((rv) => {
            const { options } = rv;
            const os = options.map((option) => {
              return formatThValue(option);
            });
            const osOri = options.map((option) => {
              return option;
            });
            rv.formatOptions = os;
            rv.title = os.join(" / ");
            rv.titleOri = osOri.join(" / ");
            return rv;
          });

          this.redundantVariants = redundantVariants;
        }

        this.showCheckVariant();
        Toast.clear();
      } else {
        Toast.clear();
        if (error) Toast.fail(error.message);
      }
    },
    toTakeOff() {
      this.$router.replace({
        name: "takeoff",
      });
    },
    //筛选选中的variant
    showCheckVariant() {
      let checkOpts = this.options.map((it) => {
        let rule = it.values.find((value) => {
          return value.checked;
        });
        return rule && { name: it.name, value: rule.value };
      });

      let findVariant = this.variants.edges.find(({ node: variant }) => {
        const { optionsMatch } = variant;

        let correspond = true;
        checkOpts.forEach((opt) => {
          correspond =
            correspond && optionsMatch && optionsMatch[opt.name] == opt.value;
        });
        return correspond;
      });

      let findRedundantVariant;
      if (!findVariant && this.redundantVariants) {
        findRedundantVariant = this.redundantVariants.find((variant) => {
          const { formatOptions } = variant;
          let correspond = true;
          checkOpts.forEach((opt) => {
            correspond =
              correspond && formatOptions && formatOptions.includes(opt.value);
          });
          return correspond;
        });
      }

      let checkedVariant;
      if (findVariant) {
        const { id, title, titleOri, image, price, cny_price } =
          findVariant.node;
        checkedVariant = {
          id,
          title,
          titleOri,
          image: image && image.url,
          price,
          cny_price: cny_price && cny_price.value,
        };
      }

      if (findRedundantVariant) {
        const {
          title,
          titleOri,
          image,
          local_price: price,
          cny_price,
        } = findRedundantVariant;
        checkedVariant = { title, titleOri, image, price, cny_price };
      }

      this.checkedVariant = checkedVariant;

      if (this.checkedVariant) {
        this.getAppliedDiscount(checkedVariant);
      }
    },
    getAppliedDiscount(variant) {
      if (!variant) {
        return;
      }
      const { price, cny_price } = variant;
      const { sku_price_type, price_ranges } = this.product;

      if (sku_price_type && sku_price_type.value != "rangePrice") {
        return { price, priceRMB: cny_price };
      }

      let pr = price_ranges && price_ranges.value;
      const { local, rmb } = JSON.parse(pr);

      let priceRangeTHB = local.sort((a, b) => {
        return a.startAmount - b.startAmount;
      });

      let priceRangeRMB = rmb.sort((a, b) => {
        return a.startAmount - b.startAmount;
      });

      let disountPriceRMB;
      let disountPrice = (function (prices, quantity) {
        for (let index = 0; index < prices.length; index++) {
          const { startAmount, price } = prices[index];
          const { price: rmbPrice } = priceRangeRMB[index];
          if (index >= prices.length - 1) {
            disountPriceRMB = rmbPrice;
            return price;
          } else {
            const { startAmount: s } = prices[index + 1];
            if (quantity >= startAmount && quantity < s) {
              disountPriceRMB = rmbPrice;
              return price;
            }
          }
        }
      })(priceRangeTHB, this.quantity);

      variant.priceTH = disountPrice;
      variant.priceRMB = disountPriceRMB;
    },
    checkOption(option, variant) {
      if (variant.checked) return;

      option.values.forEach((v) => {
        v.checked = v.value === variant.value;
      });

      this.showCheckVariant();
    },
    imagePreview() {
      if (this.checkedVariant.image) ImagePreview([this.checkedVariant.image]);
    },
    showSkuByAction(action) {
      this.actionType = action;
      this.optionSelectShow = true;
      setTimeout(() => {
        this.getAppliedDiscount(this.checkedVariant);
      }, 200);
    },
    actionConfirm() {
      switch (this.actionType) {
        case "buynow":
          {
            if (!isLogin()) {
              Toast(this.$t("Please login"));
              setTimeout(() => {
                toAuthorizationLogin({
                  name: "Product",
                  query: { id: this.id, auth: true },
                });
              }, 500);
              return;
            }

            if (!this.checkedVariant) {
              Toast.fail(this.$t("缺货"));
              return;
            }

            const { seller_member_id, price_ranges, sku_price_type } =
              this.product;

            const {
              id: productId,
              title: productTitle,
              vendor,
              featuredImage,
              local_title,
            } = this.product;

            const { id, title, titleOri, image, price, cny_price } =
              this.checkedVariant;

            const variants = [
              {
                productId,
                productTitle: local_title ? local_title.value : productTitle,
                productTitleOri: productTitle,
                productImage: featuredImage && featuredImage.url,
                image: image ? image.url : "",
                sku: title,
                price,
                cny_price,
                variantId: id,
                skuOri: titleOri,
                quantity: this.quantity,
                metafields: {
                  seller_member_id: seller_member_id && seller_member_id.value,
                  price_ranges: price_ranges && price_ranges.value,
                  sku_price_type: sku_price_type && sku_price_type.value,
                },
                vendor,
              },
            ];

            const products = [
              {
                id: productId,
                title: local_title ? local_title.value : productTitle,
                image: featuredImage && featuredImage.url,
                variants,
              },
            ];

            this.$store.commit("setOrderId", null);
            this.$store.commit("setCheckedSellers", [
              {
                seller_member_id: seller_member_id.value,
                products,
              },
            ]);
            this.$router.push({ name: "placeOrder" });
          }
          break;
        case "addtocart": {
          this.addCart();
          trackAddToCart(this.id, this.product.title, this.checkedVariant.price, this.quantity);
          break;
        }
      }
    },
    addCart() {
      if (!this.checkedVariant) {
        Toast.fail(this.$t("缺货"));
        return;
      }

      const { seller_member_id, price_ranges, sku_price_type } = this.product;
      const { id, title, titleOri, image, price, cny_price } =
        this.checkedVariant;
      const {
        id: productId,
        title: productTitle,
        local_title,
        vendor,
        featuredImage,
      } = this.product;

      const payload = {
        productId,
        productTitle: local_title ? local_title.value : productTitle,
        productTitleOri: productTitle,
        productImage: featuredImage && featuredImage.url,
        variantId: id,
        image,
        price,
        quantity: this.quantity,
        sku: title,
        skuOri: titleOri,
        vendor,
        cny_price,
        metafields: {
          seller_member_id: seller_member_id && seller_member_id.value,
          price_ranges: price_ranges && price_ranges.value,
          sku_price_type: sku_price_type && sku_price_type.value,
        },
      };
      addCart(payload);
      this.getCartCount();
      Toast({
        message: this.$t("add cart success"),
        position: "bottom",
      });
      this.optionSelectShow = false;
    },
    //商品更新中查询更新结果
    querySyncAli88Result() {
      this.cancelLoadingText = this.$t("正在更新数据...");
      this.cancelLoadingShow = true;
      this.retryTime = 0;
      this.querySyncFinish = false;

      this.interval = setInterval(() => {
        this.retryTime++;
        if (this.querySyncFinish) {
          this.cancelLoadingShow = false;
          clearInterval(this.interval);
        }
        if (this.retryTime >= this.maxRetryTime) {
          Toast.fail(this.$t("Sync failed"));
          this.cancelLoadingShow = false;
          clearInterval(this.interval);
        } else this.queryAli1688SearchResult();
      }, 3000);
      this.queryAli1688SearchResult();
    },
    //商品更新中查询更新结果
    async queryAli1688SearchResult() {
      //todo 查询同步结果
      const res = await query1688SyncResult(this.itemId);
      const { success, data, message } = res;

      if (success) {
        if (!data) return;
        const { targetItemId } = data;
        Toast.clear();
        if (targetItemId) {
          this.cancelLoadingShow = false;
          clearInterval(this.interval);
          this.querySyncFinish = true;
          this.queryProduct();
        }
      } else Toast.fail(message);
    },
    cancelWait() {
      this.cancelLoadingShow = false;
      clearInterval(this.interval);
    },
  },
  mounted() {
    const { id } = this.$route.query;
    this.id = id;
    this.getCartCount();
    this.queryProduct();
    if (this.product) {
      trackViewContent(id, this.product.title,
        this.product.priceRange.minVariantPrice.amount / 100);
    }
  },
};
</script>

<style lang="less" scoped>
@primaryColor: #ee502f;
@stepper-active-color: #ee502f;
@stepper-background-color: #ee502f;

.title {
  font-size: 1rem;
  color: #333;
  display: block;
  padding: 10px 15px;
  font-weight: bold;
}

.content {
  padding: 0px 0px 50px;
}

:deep(.content img) {
  width: 100%;
  object-fit: fill;
  display: block;
  margin-top: -0.5px;
}

.add-to-bag {
  position: fixed;
  left: 0px;
  height: 55px;
  right: 0px;
  display: flex;
  align-items: center;
  z-index: 5;
  font-size: 1rem;
  bottom: 0px;
  background-color: white;
}

.cart-buy-item {
  flex: 1;
  display: table-cell;
  align-items: center;
  text-align: center;
  background-color: white;
  color: #ee502f;
}

.cart-divider {
  width: 1px;
  height: 15px;
  background-color: #ee502f;
}

.option-select-content {
  height: 450px;
  padding: 15px;
  position: relative;
  padding-bottom: 80px;
}

.options-middle {
  padding: 100px 0px 65px 0px;
}

.option-name {
  color: #333;
  font-weight: bold;
  display: block;
  padding: 10px 0px;
}

.option-unselect {
  display: inline-block;
  padding: 5px;
  border: 1px solid white;
  background-color: #f2f2f2;
  margin: 5px 10px 5px 0px;
  color: #333;
  border-radius: 5px;
}

.option-select {
  display: inline-block;
  padding: 5px;
  border: 1px solid @primaryColor;
  border-radius: 5px;
  background-color: white;
  margin: 5px 10px 5px 0px;
  color: @primaryColor;
  border-radius: 5px;
}

.cart-confirm {
  color: white;
  background-color: #ee502f;
  height: 40px;
  border-radius: 20px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 15px;
  right: 15px;
}

.action-loading {
  background-color: #ffaaa4;
}

.select-product-detail {
  display: flex;
  align-items: center;
  position: absolute;
  padding: 15px;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: white;
}

.product-price {
  color: #ee502f;
  font-size: 1.2rem;
}

.variant-price {
  color: #ee502f;
  font-size: 1.2rem;
  background-color: white;
}

.product-rules {
  color: #333;
  font-size: 0.8rem;
}

:deep(.van-loading) {
  display: inline-block;
}

:deep(.van-loading__circular) {
  display: inline-block;
}

.navi-bar {
  position: fixed;
  top: 15px;
  left: 0;
  right: 0;
  z-index: 2;
}

.navi-back {
  width: 40px;
  height: 40px;
  background-color: #ffffff80;
  border-radius: 20px;
  text-align: center;
  display: inline-block;
  z-index: 2;
  margin-left: 15px;
}

.navi-right {
  display: inline-block;
  position: absolute;
  right: 15px;
}

.margin-top-145 {
  top: 145px !important;
}

.navi-home {
  display: inline-block;
  width: 40px;
  height: 40px;
  display: inline-block;
  background-color: #ffffff80;
  border-radius: 20px;
  text-align: center;
}

.shopee-item {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 2;
  display: flex;
  background-color: white;
  padding: 15px;
  margin-bottom: 10px;
}

.shopee-item-content {
  flex: 1;
  padding: 10px;
  color: #555;
}

.product-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  font-size: 0.9rem;
  text-align: start;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.shopee-price {
  color: red;
  margin-right: 15px;
  font-size: 0.9em;
}

.shopee-sold {
  display: inline-block;
  font-size: 0.8em;
}

.range-price {
  margin-top: 10px;
  background-color: white;
}

.range-price-price {
  color: red;
  text-align: center;
}

.range-price-start-amount {
  font-size: 0.85rem;
}

.margin-top-140 {
  margin-top: 140px;
}

.shipping-item {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  color: #969799;
  font-size: 14px;
  padding: 10px 0px;
}

.shipping-icon-container {
  position: absolute;
  top: 25px;
  left: -15px;
  z-index: 1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.shipping-step-line {
  position: absolute;
  background-color: #eb6a68;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  top: 16px;
  left: -15px;
  width: 1px;
  height: 100%;
}

.shipping-icon {
  width: 28px;
  height: 28px;
  border: solid 1px #eb6a68;
  border-radius: 20px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  background-color: white;
}

.shipping-title {
  color: #555;
  padding-left: 10px;
}

.stockout {
  display: inline-block;
  color: red;
  line-height: 25px;
  padding: 0px 15px;
}

.van-toast-cancel {
  position: fixed;
  top: 50%;
  left: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: content-box;
  width: 88px;
  max-width: 70%;
  min-height: 88px;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  text-align: center;
  word-break: break-all;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.cancel-loading {
  display: inline-block;
  padding: 5px 0px;
}

.cancel-loading-cancel {
  display: inline-block;
  border: solid white 1px;
  border-radius: 5px;
  padding: 3px 10px;
}

:deep(.van-overlay) {
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 500px;
}

:deep(.van-popup) {
  max-width: 500px;
  left: 50%;
  transform: translate(-50%, 0);
}

:deep(.van-action-sheet__content::-webkit-scrollbar) {
  display: none;
  /* Chrome Safari */
}

:deep(.van-image-preview) {
  height: 80vh;
  top: 10vh;
}
</style>